export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type CompareTo = {
  __typename?: 'CompareTo';
  code: CompareToCode;
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export enum CompareToCode {
  PreviousMonth = 'PREVIOUS_MONTH',
  PreviousPeriod = 'PREVIOUS_PERIOD',
  PreviousYear = 'PREVIOUS_YEAR'
}

export type CountUnreadNotifications = {
  __typename?: 'CountUnreadNotifications';
  tab: NotificationTab;
  value: Scalars['Int']['output'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type Dash = {
  __typename?: 'Dash';
  brandName?: Maybe<Scalars['String']['output']>;
  brandPicture?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<InsightsCategory>>;
  compareTo?: Maybe<CompareTo>;
  created?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDemo: Scalars['Boolean']['output'];
  isFavorite: Scalars['Boolean']['output'];
  labels?: Maybe<Array<Label>>;
  name: Scalars['String']['output'];
  numberOfWidgets: Scalars['Int']['output'];
  period?: Maybe<Period>;
  profiles?: Maybe<Array<DashSourceProfile>>;
  scheduleDeliveryEmails?: Maybe<Array<Scalars['String']['output']>>;
  scheduleFrequency?: Maybe<ScheduleFrequency>;
  scheduleMonthlyDay?: Maybe<Scalars['Int']['output']>;
  scheduleTime?: Maybe<Scalars['String']['output']>;
  scheduleWeekDay?: Maybe<ScheduleWeekDay>;
  shareExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  shareRef?: Maybe<Scalars['String']['output']>;
  since?: Maybe<Scalars['DateTime']['output']>;
  sources?: Maybe<Array<DashSource>>;
  theme?: Maybe<Theme>;
  until?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DashFilters = {
  __typename?: 'DashFilters';
  compareTo?: Maybe<CompareTo>;
  labels?: Maybe<Array<Label>>;
  period?: Maybe<Period>;
  profiles?: Maybe<Array<DashSourceProfile>>;
  since?: Maybe<Scalars['DateTime']['output']>;
  until?: Maybe<Scalars['DateTime']['output']>;
};

export enum DashListType {
  Demos = 'DEMOS',
  Mine = 'MINE',
  Suggested = 'SUGGESTED',
  Templates = 'TEMPLATES'
}

export type DashOrderBy = {
  field: DashOrderByField;
  sort?: InputMaybe<SortOrder>;
};

export enum DashOrderByField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type DashProfileArgs = {
  dashSourceId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type DashSource = {
  __typename?: 'DashSource';
  code: DashSourceCode;
  group: DashSourceGroup;
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export enum DashSourceCode {
  Facebook = 'FACEBOOK',
  FacebookPublic = 'FACEBOOK_PUBLIC',
  GoogleMyBusiness = 'GOOGLE_MY_BUSINESS',
  Instagram = 'INSTAGRAM',
  InstagramPublic = 'INSTAGRAM_PUBLIC',
  Linkedin = 'LINKEDIN',
  MetaAds = 'META_ADS',
  Pinterest = 'PINTEREST',
  Threads = 'THREADS',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE'
}

export enum DashSourceGroup {
  Competitors = 'COMPETITORS',
  Profiles = 'PROFILES'
}

export type DashSourceProfile = {
  __typename?: 'DashSourceProfile';
  dashSource: DashSource;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  status: DashSourceProfileStatus;
  username?: Maybe<Scalars['String']['output']>;
};

export enum DashSourceProfileStatus {
  Active = 'ACTIVE',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Expiring = 'EXPIRING',
  NotAvailable = 'NOT_AVAILABLE',
  Paused = 'PAUSED',
  Suspended = 'SUSPENDED'
}

export type InsightsCategory = {
  __typename?: 'InsightsCategory';
  color: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  date: Scalars['String']['output'];
  docNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  value: InvoiceValue;
};

export type InvoiceValue = {
  __typename?: 'InvoiceValue';
  amount: Scalars['Float']['output'];
  currency: Currency;
};

export type InvoicingInfo = {
  __typename?: 'InvoicingInfo';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: Country;
  isPerson: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type Label = {
  __typename?: 'Label';
  color: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export type Metric = {
  __typename?: 'Metric';
  description?: Maybe<Scalars['String']['output']>;
  formula?: Maybe<Scalars['String']['output']>;
  groupByOp?: Maybe<MetricGroupByOp>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  source?: Maybe<DashSource>;
  type: MetricType;
};

export enum MetricGroupByKey {
  AutoBreakdown = 'AUTO_BREAKDOWN',
  Date = 'DATE',
  DateWeekHour = 'DATE_WEEK_HOUR',
  Metric = 'METRIC',
  Post = 'POST',
  PostFormat = 'POST_FORMAT',
  PostHashtags = 'POST_HASHTAGS',
  PostType = 'POST_TYPE',
  Profile = 'PROFILE',
  Total = 'TOTAL'
}

export enum MetricGroupByOp {
  Avg = 'AVG',
  LastValue = 'LAST_VALUE',
  Sum = 'SUM'
}

export enum MetricType {
  Breakdown = 'BREAKDOWN',
  Date = 'DATE',
  DateWeekHour = 'DATE_WEEK_HOUR',
  Image = 'IMAGE',
  Number = 'NUMBER',
  String = 'STRING',
  Url = 'URL'
}

export type Mutation = {
  __typename?: 'Mutation';
  addBlankWidget: Widget;
  addCompetitors: Scalars['Boolean']['output'];
  addInsightWidget: Array<Widget>;
  addNoteWidget: Widget;
  cancelSubscription: Scalars['Boolean']['output'];
  changePaymentMethod: Scalars['String']['output'];
  clearAllNotifications: Scalars['Boolean']['output'];
  clearNotification: Scalars['Boolean']['output'];
  confirmUserChangeEmail: Scalars['Boolean']['output'];
  createDash: Dash;
  createLabel: Label;
  deleteAccount: Scalars['Boolean']['output'];
  deleteCompetitors: Array<Scalars['String']['output']>;
  deleteDash: Scalars['Boolean']['output'];
  deleteDashWidget: Widget;
  deleteInvoicingInfo: Scalars['Boolean']['output'];
  deleteUserPicture: Scalars['Boolean']['output'];
  deleteWorkspacePicture: Scalars['Boolean']['output'];
  downloadInvoice: Scalars['String']['output'];
  duplicateDash: Dash;
  duplicateWidget: Widget;
  editDash: Dash;
  editDashWidgetsPosition: Array<Widget>;
  editInsightWidget: Widget;
  editNoteWidgetText: Widget;
  exportUserData: Scalars['String']['output'];
  logout: Scalars['Boolean']['output'];
  readAllNotifications: Scalars['Boolean']['output'];
  readNotification: Scalars['Boolean']['output'];
  requestUserChangeEmail: Scalars['Boolean']['output'];
  resendRegisterConfirmEmail: Scalars['Boolean']['output'];
  saveDashFilters: DashFilters;
  subscribe: Subscribe;
  switchWorkspace: Workspace;
  toggleFavoriteDash: Array<Dash>;
  toggleFavoriteWidget: Array<Widget>;
  unclearNotification: Scalars['Boolean']['output'];
  unreadNotification: Scalars['Boolean']['output'];
  updateAccountTimezone: User;
  updateBillingEmail?: Maybe<Scalars['String']['output']>;
  updateInvoicingInfo: InvoicingInfo;
  updateLanguage: User;
  updateUserInfo: User;
  updateUserPicture: Scalars['Boolean']['output'];
  updateWorkspaceInfo: Scalars['Boolean']['output'];
  updateWorkspaceLanguage: Scalars['Boolean']['output'];
  updateWorkspacePicture: Scalars['Boolean']['output'];
  userResourceDismissDismiss: UserResourceDismissResource;
  userResourceDismissRevertDismiss: UserResourceDismissResource;
};


export type MutationAddBlankWidgetArgs = {
  dashId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  periodId: Scalars['String']['input'];
  profiles?: InputMaybe<Array<DashProfileArgs>>;
  widgetMetrics: Array<WidgetMetricInput>;
  widgetTypeId: Scalars['String']['input'];
};


export type MutationAddCompetitorsArgs = {
  netProfileIds: Array<Scalars['String']['input']>;
};


export type MutationAddInsightWidgetArgs = {
  dashId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  widgetsIds: Array<Scalars['String']['input']>;
};


export type MutationAddNoteWidgetArgs = {
  dashId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationClearAllNotificationsArgs = {
  tab?: NotificationTab;
};


export type MutationClearNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationConfirmUserChangeEmailArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateDashArgs = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  brandPicture?: InputMaybe<Scalars['String']['input']>;
  categoriesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dashId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  profiles: Array<DashProfileArgs>;
  scheduleDeliveryEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduleFrequency?: InputMaybe<ScheduleFrequency>;
  scheduleMonthlyDay?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['String']['input']>;
  scheduleWeekDay?: InputMaybe<ScheduleWeekDay>;
  themeId: Scalars['String']['input'];
};


export type MutationCreateLabelArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationDeleteAccountArgs = {
  password: Scalars['String']['input'];
};


export type MutationDeleteCompetitorsArgs = {
  competitors: Array<DashProfileArgs>;
};


export type MutationDeleteDashArgs = {
  dashesIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteDashWidgetArgs = {
  dashId: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};


export type MutationDownloadInvoiceArgs = {
  docNumber: Scalars['String']['input'];
};


export type MutationDuplicateDashArgs = {
  dashId: Scalars['String']['input'];
};


export type MutationDuplicateWidgetArgs = {
  dashId: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};


export type MutationEditDashArgs = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  brandPicture?: InputMaybe<Scalars['String']['input']>;
  categoriesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dashId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  profiles: Array<DashProfileArgs>;
  scheduleDeliveryEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduleFrequency?: InputMaybe<ScheduleFrequency>;
  scheduleMonthlyDay?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['String']['input']>;
  scheduleWeekDay?: InputMaybe<ScheduleWeekDay>;
  themeId: Scalars['String']['input'];
};


export type MutationEditDashWidgetsPositionArgs = {
  dashId: Scalars['String']['input'];
  widgetPositions: Array<WidgetPositionArgs>;
};


export type MutationEditInsightWidgetArgs = {
  dashId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  periodId?: InputMaybe<Scalars['String']['input']>;
  profiles: Array<DashProfileArgs>;
  widgetMetrics: Array<WidgetMetricInput>;
  widgetTypeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditNoteWidgetTextArgs = {
  dashId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  widgetId: Scalars['String']['input'];
};


export type MutationReadAllNotificationsArgs = {
  tab?: NotificationTab;
};


export type MutationReadNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestUserChangeEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSaveDashFiltersArgs = {
  compareToId?: InputMaybe<Scalars['String']['input']>;
  dashId: Scalars['String']['input'];
  filterLabelsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  periodId?: InputMaybe<Scalars['String']['input']>;
  profiles?: InputMaybe<Array<DashProfileArgs>>;
  since?: InputMaybe<Scalars['DateTime']['input']>;
  until?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationSubscribeArgs = {
  couponRef?: InputMaybe<Scalars['String']['input']>;
  priceRef: Scalars['String']['input'];
  referral?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSwitchWorkspaceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  idLegacy?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationToggleFavoriteDashArgs = {
  dashesIds: Array<Scalars['String']['input']>;
};


export type MutationToggleFavoriteWidgetArgs = {
  widgetsIds: Array<Scalars['String']['input']>;
};


export type MutationUnclearNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnreadNotificationArgs = {
  group?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAccountTimezoneArgs = {
  timezoneId: Scalars['String']['input'];
};


export type MutationUpdateBillingEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateInvoicingInfoArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  isPerson?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};


export type MutationUpdateLanguageArgs = {
  languageId: Scalars['String']['input'];
};


export type MutationUpdateUserInfoArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateUserPictureArgs = {
  filename: Scalars['String']['input'];
};


export type MutationUpdateWorkspaceInfoArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateWorkspaceLanguageArgs = {
  languageId: Scalars['String']['input'];
};


export type MutationUpdateWorkspacePictureArgs = {
  filename: Scalars['String']['input'];
};


export type MutationUserResourceDismissDismissArgs = {
  resource: UserResourceDismissResource;
};


export type MutationUserResourceDismissRevertDismissArgs = {
  resource: UserResourceDismissResource;
};

export type Net = {
  __typename?: 'Net';
  code: Scalars['String']['output'];
  color: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NetProfile = {
  __typename?: 'NetProfile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  net?: Maybe<Net>;
  picture?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  single?: Maybe<SingleNotification>;
  stacked?: Maybe<StackedNotification>;
};

export enum NotificationArea {
  AdsAnalytics = 'ADS_ANALYTICS',
  Analytics = 'ANALYTICS',
  Calendar = 'CALENDAR',
  CompetitorsAlerts = 'COMPETITORS_ALERTS',
  CompetitorAnalytics = 'COMPETITOR_ANALYTICS',
  Drafts = 'DRAFTS',
  Feed = 'FEED',
  General = 'GENERAL',
  Inbox = 'INBOX',
  Influencer = 'INFLUENCER',
  ProduceContent = 'PRODUCE_CONTENT',
  Profiles = 'PROFILES',
  Rss = 'RSS',
  TeamManagement = 'TEAM_MANAGEMENT',
  TeamWorkflow = 'TEAM_WORKFLOW'
}

export enum NotificationButton {
  InstantDownload = 'INSTANT_DOWNLOAD',
  MultiRedirect = 'MULTI_REDIRECT',
  Redirect = 'REDIRECT'
}

export enum NotificationTab {
  All = 'ALL',
  Cleared = 'CLEARED',
  Warning = 'WARNING'
}

export enum NotificationVariant {
  Error = 'ERROR',
  General = 'GENERAL',
  Info = 'INFO',
  Success = 'SUCCESS',
  User = 'USER',
  Warning = 'WARNING'
}

export type PaginatedCompareTos = {
  __typename?: 'PaginatedCompareTos';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<CompareTo>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedDashSourceProfiles = {
  __typename?: 'PaginatedDashSourceProfiles';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<DashSourceProfile>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedDashSources = {
  __typename?: 'PaginatedDashSources';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<DashSource>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedDashs = {
  __typename?: 'PaginatedDashs';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Dash>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedGroupNotifications = {
  __typename?: 'PaginatedGroupNotifications';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<SingleNotification>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedInsightsCategories = {
  __typename?: 'PaginatedInsightsCategories';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<InsightsCategory>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedInvoice = {
  __typename?: 'PaginatedInvoice';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Invoice>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedLabels = {
  __typename?: 'PaginatedLabels';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Label>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedMetrics = {
  __typename?: 'PaginatedMetrics';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Metric>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedNetTypes = {
  __typename?: 'PaginatedNetTypes';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Net>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Notification>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedPeriods = {
  __typename?: 'PaginatedPeriods';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Period>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedThemes = {
  __typename?: 'PaginatedThemes';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Theme>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedWidgetTypes = {
  __typename?: 'PaginatedWidgetTypes';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<WidgetType>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedWidgets = {
  __typename?: 'PaginatedWidgets';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Widget>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaymentDefaultMethod = {
  __typename?: 'PaymentDefaultMethod';
  card?: Maybe<PaymentMethodCard>;
  paymentMethod: PaymentMethod;
  paypal?: Maybe<PaymentMethodPaypal>;
};

export enum PaymentMethod {
  Card = 'CARD',
  Other = 'OTHER',
  Paypal = 'PAYPAL'
}

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  expiration: Scalars['String']['output'];
  expired: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentMethodPaypal = {
  __typename?: 'PaymentMethodPaypal';
  email: Scalars['String']['output'];
};

export type Period = {
  __typename?: 'Period';
  code: PeriodCode;
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export enum PeriodCode {
  Last_7Days = 'LAST_7_DAYS',
  Last_28Days = 'LAST_28_DAYS',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  YearToDate = 'YEAR_TO_DATE'
}

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['String']['output'];
  isTrial: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum PlanLimit {
  AndieCredits = 'ANDIE_CREDITS',
  CompetitorsProfiles = 'COMPETITORS_PROFILES',
  DashSourceProfilesLimit = 'DASH_SOURCE_PROFILES_LIMIT',
  InsightsHistoryLimit = 'INSIGHTS_HISTORY_LIMIT',
  InsightsHistoryLimitRight = 'INSIGHTS_HISTORY_LIMIT_RIGHT',
  SocialProfiles = 'SOCIAL_PROFILES',
  TeamMembers = 'TEAM_MEMBERS'
}

export type PlanLimitsList = {
  __typename?: 'PlanLimitsList';
  current: Scalars['String']['output'];
  key: PlanLimit;
  limit: Scalars['String']['output'];
};

export type PlanPreview = {
  __typename?: 'PlanPreview';
  code: Scalars['String']['output'];
  mostPopular: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  plan: Plan;
  prices: Array<PlanPreviewPrice>;
};

export type PlanPreviewPrice = {
  __typename?: 'PlanPreviewPrice';
  currency: Currency;
  discount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  interval: PlanPriceInterval;
  isSubscribed: Scalars['Boolean']['output'];
  monthlyPrice: Scalars['Float']['output'];
};

export enum PlanPriceInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type Query = {
  __typename?: 'Query';
  compareTos: PaginatedCompareTos;
  countUnreadNotifications: Array<CountUnreadNotifications>;
  countries: Array<Country>;
  currentWorkspace: Workspace;
  dash: Dash;
  dashFilters: DashFilters;
  dashSourceProfiles: PaginatedDashSourceProfiles;
  dashSources: PaginatedDashSources;
  dashWidgets: Array<Widget>;
  dashs: PaginatedDashs;
  defaultPaymentMethod?: Maybe<PaymentDefaultMethod>;
  groupNotifications: PaginatedGroupNotifications;
  hasActiveSubscription: Scalars['Boolean']['output'];
  hasEmailValidated: Scalars['Boolean']['output'];
  hasFailedPayment: Scalars['Boolean']['output'];
  hasInvoicingTaxesChanged: Scalars['Boolean']['output'];
  insightsCategories: PaginatedInsightsCategories;
  invoices: PaginatedInvoice;
  invoicingInfo: InvoicingInfo;
  labels: PaginatedLabels;
  languages: Array<Language>;
  me: User;
  metrics: PaginatedMetrics;
  nets: PaginatedNetTypes;
  notification: Notification;
  notifications: PaginatedNotifications;
  periods: PaginatedPeriods;
  planLimits: Array<PlanLimitsList>;
  plans: Array<PlanPreview>;
  searchProfile: Array<NetProfile>;
  subscriptionPreview?: Maybe<SubscriptionPreview>;
  teamPermissions: Array<TeamPermission>;
  templateWidgets: PaginatedWidgets;
  themes: PaginatedThemes;
  timezones: Array<Timezone>;
  totalCompetitors: Scalars['Int']['output'];
  uploadUrls: Array<UploadUrl>;
  userResourceDismiss?: Maybe<UserResourceDismiss>;
  userResourcesDismiss: Array<UserResourceDismiss>;
  widget?: Maybe<Widget>;
  widgetData: WidgetData;
  widgetTypes: PaginatedWidgetTypes;
  workspaces: WorkspacePaginated;
};


export type QueryCompareTosArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDashArgs = {
  dashId: Scalars['String']['input'];
};


export type QueryDashFiltersArgs = {
  dashId: Scalars['String']['input'];
};


export type QueryDashSourceProfilesArgs = {
  dashId?: InputMaybe<Scalars['String']['input']>;
  dashSourceGroup: DashSourceGroup;
  dashSourcesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDashSourcesArgs = {
  group?: InputMaybe<DashSourceGroup>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDashWidgetsArgs = {
  dashId: Scalars['String']['input'];
};


export type QueryDashsArgs = {
  categoriesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  favoritesOnly?: Scalars['Boolean']['input'];
  orderBy?: InputMaybe<DashOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sourcesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  take?: InputMaybe<Scalars['Int']['input']>;
  type: DashListType;
};


export type QueryGroupNotificationsArgs = {
  group: Scalars['Int']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHasInvoicingTaxesChangedArgs = {
  countryId: Scalars['String']['input'];
  isPerson?: Scalars['Boolean']['input'];
  postalCode: Scalars['String']['input'];
};


export type QueryInsightsCategoriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInvoicesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLabelsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMetricsArgs = {
  dashSourcesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNetsArgs = {
  onlyCompetitors?: Scalars['Boolean']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNotificationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  tab?: NotificationTab;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPeriodsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPlanLimitsArgs = {
  planLimits: Array<PlanLimit>;
};


export type QuerySearchProfileArgs = {
  netId: Scalars['String']['input'];
  query: Scalars['String']['input'];
};


export type QueryTemplateWidgetsArgs = {
  categoriesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  favoritesOnly?: Scalars['Boolean']['input'];
  orderBy?: InputMaybe<WidgetTemplatesOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sourcesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryThemesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUploadUrlsArgs = {
  filenames: Array<Scalars['String']['input']>;
};


export type QueryUserResourceDismissArgs = {
  resource: UserResourceDismissResource;
};


export type QueryWidgetArgs = {
  widgetId: Scalars['String']['input'];
};


export type QueryWidgetDataArgs = {
  dashId: Scalars['String']['input'];
  demoData?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<WidgetDataFilterArgs>;
  formula?: InputMaybe<Scalars['String']['input']>;
  formulaName?: InputMaybe<Scalars['String']['input']>;
  formulaUnit?: InputMaybe<Scalars['String']['input']>;
  groupBy1: MetricGroupByKey;
  groupBy2: MetricGroupByKey;
  metrics: Array<WidgetDataMetricArgs>;
  profiles: Array<WidgetDataProfileArgs>;
  since: Scalars['DateTime']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<WidgetDataSortArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
  until: Scalars['DateTime']['input'];
  widgetTypeId: Scalars['String']['input'];
};


export type QueryWidgetTypesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkspacesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export enum ScheduleFrequency {
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum ScheduleWeekDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type SingleNotification = {
  __typename?: 'SingleNotification';
  actions?: Maybe<Array<SingleNotificationActions>>;
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  group: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  variant: NotificationVariant;
};

export type SingleNotificationActions = {
  __typename?: 'SingleNotificationActions';
  data: Scalars['JSON']['output'];
  nameKey: Scalars['String']['output'];
  type: NotificationButton;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StackedNotification = {
  __typename?: 'StackedNotification';
  area: NotificationArea;
  count: Scalars['Int']['output'];
  group: Scalars['Int']['output'];
  isRead: Scalars['Boolean']['output'];
  last2: Array<StackedNotificationLast2>;
  variant: NotificationVariant;
};

export type StackedNotificationLast2 = {
  __typename?: 'StackedNotificationLast2';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
};

export type Subscribe = {
  __typename?: 'Subscribe';
  redirectToSubscribe: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPreview = {
  __typename?: 'SubscriptionPreview';
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']['output']>;
  currency?: Maybe<Currency>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  interval?: Maybe<PlanPriceInterval>;
  intervalCount?: Maybe<Scalars['Int']['output']>;
  nextPaymentAmount?: Maybe<Scalars['Float']['output']>;
  plan: Plan;
  status: SubscriptionPreviewStatus;
};

export enum SubscriptionPreviewStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Overdue = 'OVERDUE'
}

export enum TeamPermission {
  Billing = 'BILLING',
  CrudCompetitors = 'CRUD_COMPETITORS',
  ManageInsights = 'MANAGE_INSIGHTS',
  Owner = 'OWNER',
  ViewInsights = 'VIEW_INSIGHTS'
}

export type Theme = {
  __typename?: 'Theme';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nameKey: Scalars['String']['output'];
};

export type Timezone = {
  __typename?: 'Timezone';
  id: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  timezoneKey: Scalars['String']['output'];
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  filename: Scalars['String']['output'];
  newFilename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  color: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language?: Maybe<Language>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  publicId: Scalars['Int']['output'];
  socketToken?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Timezone>;
};

export type UserResourceDismiss = {
  __typename?: 'UserResourceDismiss';
  resource: UserResourceDismissResource;
};

export enum UserResourceDismissResource {
  DashDemo = 'DASH_DEMO',
  DashNeedHelp = 'DASH_NEED_HELP',
  DashSuggestedTemplates = 'DASH_SUGGESTED_TEMPLATES'
}

export type Widget = {
  __typename?: 'Widget';
  categories?: Maybe<Array<InsightsCategory>>;
  created?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filterMetric?: Maybe<Metric>;
  filterText?: Maybe<Scalars['String']['output']>;
  formula?: Maybe<Scalars['String']['output']>;
  formulaName?: Maybe<Scalars['String']['output']>;
  formulaUnit?: Maybe<Scalars['String']['output']>;
  groupBy1: MetricGroupByKey;
  groupBy2: MetricGroupByKey;
  id: Scalars['String']['output'];
  isFavorite: Scalars['Boolean']['output'];
  metrics?: Maybe<Array<WidgetMetric>>;
  name: Scalars['String']['output'];
  period?: Maybe<Period>;
  position: Array<Scalars['Int']['output']>;
  profiles?: Maybe<Array<DashSourceProfile>>;
  since?: Maybe<Scalars['DateTime']['output']>;
  sortAsc?: Maybe<Scalars['Boolean']['output']>;
  sortMetric?: Maybe<Metric>;
  sources?: Maybe<Array<DashSource>>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<WidgetType>;
  until?: Maybe<Scalars['DateTime']['output']>;
};

export type WidgetData = {
  __typename?: 'WidgetData';
  error?: Maybe<WidgetDataError>;
  rowCount?: Maybe<Scalars['Int']['output']>;
  rows: Array<WidgetDataRow>;
};

export enum WidgetDataError {
  EmptyState = 'EMPTY_STATE',
  MissingProfile = 'MISSING_PROFILE',
  WrongFilters = 'WRONG_FILTERS'
}

export type WidgetDataFilterArgs = {
  metricId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type WidgetDataMetricArgs = {
  breakdownKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  letter: Scalars['String']['input'];
  metricId: Scalars['String']['input'];
};

export type WidgetDataProfileArgs = {
  dashSourceId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type WidgetDataRow = {
  __typename?: 'WidgetDataRow';
  key: Scalars['String']['output'];
  label: WidgetLabel;
  values: Array<WidgetDataRowValues>;
};

export type WidgetDataRowValues = {
  __typename?: 'WidgetDataRowValues';
  key: Scalars['String']['output'];
  label: WidgetLabel;
  str?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type WidgetDataSortArgs = {
  metricId: Scalars['String']['input'];
  sortAsc: Scalars['Boolean']['input'];
};

export type WidgetLabel = {
  __typename?: 'WidgetLabel';
  breakdownKeys?: Maybe<Array<Scalars['String']['output']>>;
  date?: Maybe<Scalars['DateTime']['output']>;
  formula?: Maybe<Scalars['String']['output']>;
  letter?: Maybe<Scalars['String']['output']>;
  metric?: Maybe<Metric>;
  profile?: Maybe<DashSourceProfile>;
  str?: Maybe<Scalars['String']['output']>;
  type: MetricGroupByKey;
};

export type WidgetMetric = {
  __typename?: 'WidgetMetric';
  breakdownKeys: Array<Scalars['String']['output']>;
  letter: Scalars['String']['output'];
  metric: Metric;
};

export type WidgetMetricInput = {
  letter: Scalars['String']['input'];
  metricId: Scalars['String']['input'];
};

export type WidgetPositionArgs = {
  position: Array<Scalars['Int']['input']>;
  widgetId: Scalars['String']['input'];
};

export type WidgetTemplatesOrderBy = {
  field: WidgetTemplatesOrderByField;
  sort?: InputMaybe<SortOrder>;
};

export enum WidgetTemplatesOrderByField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type WidgetType = {
  __typename?: 'WidgetType';
  code: WidgetTypeCode;
  id: Scalars['String']['output'];
  maxHeight: Scalars['Int']['output'];
  maxWidth: Scalars['Int']['output'];
  minHeight: Scalars['Int']['output'];
  minWidth: Scalars['Int']['output'];
  nameKey: Scalars['String']['output'];
};

export enum WidgetTypeCode {
  Bars = 'BARS',
  BarsHorizontal = 'BARS_HORIZONTAL',
  BigNumber = 'BIG_NUMBER',
  Feed = 'FEED',
  Lines = 'LINES',
  Note = 'NOTE',
  Pie = 'PIE',
  Table = 'TABLE',
  WorldMap = 'WORLD_MAP'
}

export type Workspace = {
  __typename?: 'Workspace';
  billingEmail?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  language: Language;
  name: Scalars['String']['output'];
  owner: User;
  picture?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  totalMembers: Scalars['Int']['output'];
};

export type WorkspacePaginated = {
  __typename?: 'WorkspacePaginated';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Workspace>>;
  total?: Maybe<Scalars['Int']['output']>;
};
