import { useEffect, useState } from 'react';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rewardful: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Rewardful: any;
    }
}

export const useRewardful = () => {
    const [referral, setReferral] = useState<string>();
    const [coupon, setCoupon] = useState<string>();

    useEffect(() => {
        if (!window.rewardful) {
            return;
        }
        window.rewardful('ready', function () {
            setReferral(window.Rewardful?.referral);
            setCoupon(window.Rewardful?.coupon?.id);
        });
    }, []);

    return { coupon, referral: referral || undefined };
};
