import {
    withTolgee,
    Tolgee,
    I18nextPlugin,
    DevTools,
    BackendFetch,
    TreeTranslationsData,
} from '@tolgee/i18next';
import i18next from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { FALLBACK_LOCALE, LANGUAGE_COOKIE, getOptions } from './settings';
import dayjs from 'dayjs';

const i18nInitOptions = {
    ...getOptions(),
    detection: {
        // order and from where user language should be detected
        order: ['cookie', 'navigator'],

        // keys or params to lookup language from
        lookupCookie: LANGUAGE_COOKIE,

        // This will automatically update the cookie
        caches: ['cookie'],
    },
    ns: ['translation', 'languages', 'countries', 'timezones'],

    // print missing keys
    //saveMissing: true,
    //missingKeyHandler: function (lng, ns, key) {
    //    console.log(key);
    //},
};

const generateStaticData = () => {
    const data: Record<string, () => Promise<TreeTranslationsData>> = {};

    (i18nInitOptions.supportedLngs || []).forEach((lang) => {
        i18nInitOptions.ns.forEach((n) => {
            data[`${lang}:${n}`] = () => import(`./locales/${lang}/${n}.json`).then((m) => m.default);
        });
    });

    return data;
};

// Initialize Tolgee translation tool
// Dev tools may be cause performance issues on translated inputs when enabled
const tolgee = Tolgee().use(DevTools()).use(I18nextPlugin()).use(BackendFetch()).init({
    defaultLanguage: FALLBACK_LOCALE,
    staticData: generateStaticData(),
});

i18next.on('languageChanged', function (lng) {
    dayjs.locale(lng);
});

// Initialize i18next for the client side
withTolgee(i18next, tolgee).use(ICU).use(LanguageDetector).use(initReactI18next).init(i18nInitOptions);

export default i18next;
