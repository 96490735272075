import { useGlobalLoading } from '@/providers/global-loading-provider';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { SubscribeDocument } from '@/graphql/generated/subscribe.generated';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useRewardful } from '@/utils/rewarful';

export default function Subscribe() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { setGlobalLoading } = useGlobalLoading();
    const { coupon, referral } = useRewardful();
    const [subscribe] = useMutation(SubscribeDocument, {
        variables: { priceRef: id || '', couponRef: coupon, referral },
        onCompleted: (data) => {
            if (data.subscribe.url) {
                window.location.href = data.subscribe.url;
            } else {
                setGlobalLoading(false);
                enqueueSnackbar(t('systemsnackbars.billing.subscription.completed.success'));
                navigate('/');
            }
        },
        onError: (e) => {
            setGlobalLoading(false);
            enqueueSnackbar(t(e.message), { preventDuplicate: true });
            navigate('/');
        },
    });

    useEffect(() => {
        setGlobalLoading(true);
        subscribe();
    }, [subscribe, setGlobalLoading]);

    return null;
}
