import { ApolloQueryResult, QueryResult } from '@apollo/client';
import { Button, ButtonProps, Stack } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type LoadMoreProps = {
    loadMore: <T>() => Promise<QueryResult | ApolloQueryResult<T>>;
    buttonProps?: Omit<ButtonProps, 'onClick'>;
};
const LoadMore = ({ loadMore, buttonProps }: LoadMoreProps) => {
    const [isInView, setIsInView] = useState(false);
    const ref = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting);
            },
            { threshold: 0.5 },
        );

        const element = ref.current;
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    useEffect(() => {
        if (isInView) {
            loadMore();
        }
    }, [isInView, loadMore]);

    return (
        <Stack>
            {!buttonProps ? (
                <Stack ref={ref} height={4} />
            ) : (
                <Button {...buttonProps} onClick={loadMore}>
                    {t('str.load-more')}
                </Button>
            )}
        </Stack>
    );
};

export default LoadMore;
