import { FeedbackRounded } from '@mui/icons-material';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FeedbackButton = () => {
    const { t } = useTranslation();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const wProductFruits = window.productFruits;

    const disposeFn = () => {
        wProductFruits.api.surveys.startSurvey(import.meta.env.VITE_PRODUCT_FRUITS_FEEDBACK_SURVEY_ID);
    };

    return (
        <Button
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<FeedbackRounded />}
            onClick={disposeFn}
            sx={{ ...(isMd && { '& .MuiButton-startIcon': { marginRight: '0px' } }) }}
        >
            {isMd ? null : t('str.help.feature.feedback')}
        </Button>
    );
};
export default FeedbackButton;
